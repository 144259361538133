import { Link } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { FormOutlined } from "@ant-design/icons";
import { Button, Row, Col, List, message } from "antd";
import { useQuery } from "@apollo/client";
import Api from "api";
import LoadingSpinner from "../../Common/LoadingSpinner";
import CustomPageHeader from "components/Common/PageHeader/PageHeader";

import { DeliverableDocumentNode } from "types";
import SmartTable from "components/Common/Tables";

const ManageDocuments = () => {
  useDocumentTitle("Documents Editor");

  const { data, loading } = useQuery(Api.Document.GetAll(), {
    onError: (error) => {
      console.log(error.message);
      message.error(error.message, 5);
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const documents: DeliverableDocumentNode | any = data?.allDeliverableDocuments;
  console.log({ Documents: documents });

  const sortedDocuments = [...documents].sort((a, b) => a?.name.localeCompare(b?.name));

  const columns = [
    {
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "formNumber",
      title: "Form Number",
    },
    {
      dataIndex: "name",
      title: "Action",
      align: "center",
      render: (_, doc) => (
        <Link
          to={`/administration/document-editor/manage/${doc.documentId}`}
          state={{
            documentId: doc.documentId,
            id: doc.id,
          }}
        >
          <Button type="primary" icon={<FormOutlined />}>
            Edit
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <CustomPageHeader
        className="shadowed-static"
        title="Document Editor"
        icon={<FormOutlined style={{ color: "rgb(24, 144, 255)" }} />}
      />
      <Row align="middle" style={{ textAlign: "left" }}>
        <Col span={24}>
          <SmartTable data={sortedDocuments} columns={columns} />
        </Col>
      </Row>
    </>
  );
};

export default ManageDocuments;
