import { gql } from "graphql-tag";

export const Document = {
  GetAll: () => gql`
    query getDeliverableDocuments {
      allDeliverableDocuments {
        id
        documentId
        name
        formNumber
        revisionDate
        numberOfPages
        numberOfVariables
        variableFields {
          id
          pageNumber
          fieldName
          fieldType
          fieldNumber
          # answer {
          #   id
          # }
        }
      }
    }
  `,
  GetAppDocument: () => gql`
    query getApplicationDocument($applicationId: String!, $documentId: String!) {
      applicationDocument(applicationId: $applicationId, documentId: $documentId) {
        id
        fieldName
        pageNumber
        field {
          ## will not have real application answer
          fieldName
          id
          fieldNumber
          fieldType
          pageNumber
          answer {
            ## this is ADMIN answer
            id
            answer
          }
        }
        applicationAnswer {
          ## answer to use for applicant
          answer
          answerText
          isSaved
          id
          applicationId
        }
      }
    }
  `,
  GetOne: () => gql`
    query getDeliverableDocument($id: String!) {
      document(id: $id) {
        id
        documentId
        name
        formNumber
        revisionDate
        numberOfPages
        numberOfVariables
        variableFields {
          id
          pageNumber
          fieldName
          fieldType
          fieldNumber
          answer {
            answer
            answerType
            id
            question {
              id
              question
            }
          }
        }
      }
    }
  `,
  GetOneVariableFields: () => gql`
    query getDeliverableDocumentVariableFields($id: String!) {
      document(id: $id) {
        variableFields {
          id
          pageNumber
          fieldName
          fieldType
          fieldNumber
          answer {
            answer
            answerType
            id
            question {
              id
              question
            }
          }
        }
      }
    }
  `,
  Update: () => gql`
    mutation updateDocumentAssignment(
      $questionId: String!
      $fieldId: String!
      $answerId: String
    ) {
      updateDocAssignment(
        questionId: $questionId
        documentFieldId: $fieldId
        answerId: $answerId
      ) {
        id
        questionId
        answerId
        documentVariableFieldId
      }
    }
  `,
};
